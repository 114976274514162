import React, { useState } from 'react';
import { testCases } from '../data/testCases';
import TestCaseCard from './TestCaseCard';
import axios from 'axios';
import { useAuth } from './AuthContext';
import LoadingSpinner from './LoadingSpinner';
import ReactPlayer from 'react-player'; // Import react-player
import './TestCasesContainer.css'; // Import the CSS file

function TestCasesContainer() {
  const [response, setResponse] = useState(null);
  const { currentUser } = useAuth();
  const [apiKey, setApiKey] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  const handleSelect = async (chatHistory) => {
    if (!currentUser) {
      alert('Please sign in to analyze chat history.');
      return;
    }
    if (!apiKey) {
      alert('Please enter your OpenAI API Key.');
      return;
    }

    try {
      setLoading(true);
      setError('');
      setResponse(null);

      const res = await axios.post('https://twingen-api-bdgmg2csc7ceb5fv.centralindia-01.azurewebsites.net/messages/rag/', {
        user_id: currentUser.uid,
        api_key: apiKey,
        messages: chatHistory,
      });

      setResponse(res.data);
    } catch (err) {
      console.error(err);
      setError('Analysis failed. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="testcases-container fade-in">
      <div className="video-player" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        {/* <h3>Watch Tutorial</h3> */}
        <ReactPlayer
          url='https://twingenfuelfiles.blob.core.windows.net/twingen-properties/twingen-demo-video.mp4'
          controls
          width="50%"
        />
      </div><br/>
      <h2>Choose a Test Case to Analyze</h2>
      <div className="cards">
        {testCases.map(tc => (
          <TestCaseCard key={tc.id} testCase={tc} onSelect={handleSelect} disabled={loading}/>
        ))}
      </div>
      <div className="api-input">
        <input 
          type="text" 
          placeholder="Enter OpenAI API Key" 
          value={apiKey}
          onChange={(e) => setApiKey(e.target.value)}
          disabled={loading} 
        />
      </div>


      {loading && <LoadingSpinner />}
      {error && <p className="error">{error}</p>}
      {response && (
        <div className="response fade-in">
          <h3>Analysis Response:</h3>
          <pre>{JSON.stringify(response, null, 2)}</pre>
        </div>
      )}
    </div>
  );
}

export default TestCasesContainer;
