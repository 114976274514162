// src/components/SignUp.js

import React, { useRef, useState } from 'react';
import { auth } from '../firebase';
import { createUserWithEmailAndPassword, updateProfile, sendEmailVerification } from 'firebase/auth';
import axios from 'axios';
import './SignUp.css'; // Import the CSS file

function SignUp() {
  const emailRef = useRef();
  const passwordRef = useRef();
  const nameRef = useRef();
  const [error, setError] = useState('');
  const [message, setMessage] = useState('');

  const handleSignUp = async (e) => {
    e.preventDefault();
    try {
      setError('');
      setMessage('');
      
      // Create user with email and password
      const userCredential = await createUserWithEmailAndPassword(
        auth,
        emailRef.current.value,
        passwordRef.current.value
      );
      
      // Update user profile with display name
      await updateProfile(userCredential.user, {
        displayName: nameRef.current.value,
      });
      
      // Send email verification
      await sendEmailVerification(userCredential.user);
      
      // Send user data to backend
      await axios.post('http://localhost:5000/register', {
        uid: userCredential.user.uid,
        email: userCredential.user.email,
        displayName: nameRef.current.value,
      });
      
      setMessage('Verification email sent. Please check your inbox.');
    } catch (err) {
      setError(err.message);
    }
  };

  return (
    <div className="signup-container fade-in">
      <h2>Sign Up</h2>
      {error && <p className="error">{error}</p>}
      {message && <p className="message">{message}</p>}
      <form onSubmit={handleSignUp}>
        <input type="text" ref={nameRef} className="input-field" placeholder="Display Name" required />
        <input type="email" ref={emailRef} className="input-field" placeholder="Email" required />
        <input type="password" ref={passwordRef} className="input-field" placeholder="Password" required />
        <button type="submit" className="btn">Sign Up</button>
      </form>
    </div>
  );
}

export default SignUp;
