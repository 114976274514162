// src/components/Navbar.js

import React, { useState, useRef, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useAuth } from './AuthContext';
import './Navbar.css'; // Import the CSS file

const Navbar = () => {
  const { currentUser, logout } = useAuth();

  const [scroll, setScroll] = useState(0);
  const [headerTop, setHeaderTop] = useState(0);

  const sceneEl = useRef(null); // Parallax reference for logo
  const backgroundEl = useRef(null); // Parallax reference for background

  // Scroll effect logic
  useEffect(() => {
    const header = document.querySelector(".navbar"); // Select navbar
    setHeaderTop(header.offsetTop); // Get header's initial top offset

    const handleScroll = () => {
      setScroll(window.scrollY); // Track scroll position
    };

    window.addEventListener("scroll", handleScroll); // Add scroll event listener

    return () => {
      window.removeEventListener("scroll", handleScroll); // Clean up event listener on unmount
    };
  }, []);

  return (
    <nav className="navbar" style={{ position: scroll > headerTop ? 'sticky': '', transition: "position 0.3s, top 0.3s", backgroundColor: scroll > headerTop ? "white" : "transparent" }}>
      <div className="container">
        {/* Logo */}
        <div className="navbar__logo">
          <Link to="/">Twingen</Link>
        </div>

        {/* Links */}
        <div className="navbar__links">
          {currentUser ? (
            <button className="logout-button" onClick={logout}>Logout</button>
          ) : (
            <>
              <Link to="/signup" className="signup-button">Sign Up</Link>
              <Link to="/signin" className="login-button">Sign In</Link>
            </>
          )}
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
