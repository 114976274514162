// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* src/styles/Spinner.css */

.spinner {
  width: 40px;
  height: 40px;
  
  position: relative;
  margin: 20px auto;
}

.double-bounce1, .double-bounce2 {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background-color: var(--accent-color);
  opacity: 0.6;
  position: absolute;
  top: 0;
  left: 0;
  
  animation: sk-bounce 2.0s infinite ease-in-out;
}

.double-bounce2 {
  animation-delay: -1.0s;
}

@keyframes sk-bounce {
  0%, 100% { transform: scale(0.0) }
  50% { transform: scale(1.0) }
}
`, "",{"version":3,"sources":["webpack://./src/components/LoadingSpinner.css"],"names":[],"mappings":"AAAA,2BAA2B;;AAE3B;EACE,WAAW;EACX,YAAY;;EAEZ,kBAAkB;EAClB,iBAAiB;AACnB;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,kBAAkB;EAClB,qCAAqC;EACrC,YAAY;EACZ,kBAAkB;EAClB,MAAM;EACN,OAAO;;EAEP,8CAA8C;AAChD;;AAEA;EACE,sBAAsB;AACxB;;AAEA;EACE,WAAW,sBAAsB;EACjC,MAAM,sBAAsB;AAC9B","sourcesContent":["/* src/styles/Spinner.css */\n\n.spinner {\n  width: 40px;\n  height: 40px;\n  \n  position: relative;\n  margin: 20px auto;\n}\n\n.double-bounce1, .double-bounce2 {\n  width: 100%;\n  height: 100%;\n  border-radius: 50%;\n  background-color: var(--accent-color);\n  opacity: 0.6;\n  position: absolute;\n  top: 0;\n  left: 0;\n  \n  animation: sk-bounce 2.0s infinite ease-in-out;\n}\n\n.double-bounce2 {\n  animation-delay: -1.0s;\n}\n\n@keyframes sk-bounce {\n  0%, 100% { transform: scale(0.0) }\n  50% { transform: scale(1.0) }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
