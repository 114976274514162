import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import SignUp from './components/SignUp';
import SignIn from './components/SignIn';
import ProtectedRoute from './components/ProtectedRoute';
import TestCasesContainer from './components/TestCasesContainer';
import { AuthProvider } from './components/AuthContext';
import Navbar from './components/Navbar'; // Import the Navbar
import Footer from './components/Footer'; // Import the Footer
import './App.css'; // Import the CSS file
import './Global.css'; // Import Global Styles
import bgImg from './assets/bg/shape1.png'; // Import the background image

function App() {
  return (
    <AuthProvider>
      <Router>
        <div style={{ backgroundImage: `url(${bgImg})`, backgroundRepeat: 'no-repeat' }}>
        <Navbar /> {/* Render the Navbar */}
        <Routes>
          <Route 
            path="/" 
            element={
              <ProtectedRoute>
                <TestCasesContainer />
              </ProtectedRoute>
            } 
          />
          <Route path="/signup" element={<SignUp />} />
          <Route path="/signin" element={<SignIn />} />
        </Routes>
        <Footer /> {/* Render the Footer */}
        </div>
      </Router>
    </AuthProvider>
  );
}

export default App;
