// src/data/testCases.js

export const testCases = [
  {
    id: 1,
    title: "Casual Conversation",
    chatHistory: [
      { "role": "user", "text": "Hi there!" },
      { "role": "system", "text": "Hello! How can I assist you today?" },
      { "role": "user", "text": "Just wanted to chat about the weather." },
      { "role": "system", "text": "It's sunny and warm today. Perfect for a walk!" },
    ],
  },
  {
    id: 2,
    title: "Technical Support",
    chatHistory: [
      { "role": "user", "text": "I'm having trouble connecting to Wi-Fi." },
      { "role": "system", "text": "I'm sorry to hear that. Have you tried restarting your router?" },
      { "role": "user", "text": "Yes, but it didn't help." },
      { "role": "system", "text": "Let's check your network settings next." },
    ],
  },
  {
    id: 3,
    title: "Emotional Support",
    chatHistory: [
      { "role": "user", "text": "I'm feeling really stressed lately." },
      { "role": "system", "text": "I'm sorry you're feeling this way. Do you want to talk about it?" },
      { "role": "user", "text": "Yes, it's been overwhelming with work and personal issues." },
      { "role": "system", "text": "It's important to take care of yourself. Have you considered speaking to a professional?" },
    ],
  },
  {
    id: 4,
    title: "Educational Inquiry",
    chatHistory: [
      { "role": "user", "text": "Can you explain the theory of relativity?" },
      { "role": "system", "text": "Certainly! The theory of relativity, developed by Einstein, includes..." },
      { "role": "user", "text": "That's fascinating. How does it apply to modern technology?" },
      { "role": "system", "text": "It underpins technologies like GPS and various aspects of modern physics." },
    ],
  },
  {
    id: 5,
    title: "Shopping Assistance",
    chatHistory: [
      { "role": "user", "text": "I'm looking for a new laptop." },
      { "role": "system", "text": "Sure! What specifications are you interested in?" },
      { "role": "user", "text": "I need something lightweight with at least 16GB RAM." },
      { "role": "system", "text": "I recommend the XYZ model. It fits your criteria and has great reviews." },
    ],
  },
];
