// src/components/AuthContext.js

import React, { useContext, useState, useEffect, createContext } from 'react';
import { auth } from '../firebase'; // Ensure this is the correct path to your firebase config
import { onAuthStateChanged, signInWithEmailAndPassword, signOut } from 'firebase/auth';

// Create the Auth Context
const AuthContext = createContext();

// Custom hook to use the Auth Context
export function useAuth() {
  return useContext(AuthContext);
}

// AuthProvider component to wrap around the app
export function AuthProvider({ children }) {
  const [currentUser, setCurrentUser] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Subscribe to authentication state changes
    const unsubscribe = onAuthStateChanged(auth, user => {
      setCurrentUser(user);
      setLoading(false);
    });

    // Cleanup subscription on unmount
    return unsubscribe;
  }, []);

  const login = async (email, password) => {
    try {
      await signInWithEmailAndPassword(auth, email, password);
    } catch (error) {
      console.error("Failed to login:", error.message);
    }
  };

  const logout = async () => {
    try {
      await signOut(auth);
      setCurrentUser(null); // Update currentUser to null after logout
    } catch (error) {
      console.error("Failed to logout:", error.message);
    }
  };

  const value = { currentUser, loading, login, logout };

  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
    </AuthContext.Provider>
  );
}
