// src/components/Spinner.js
import React from 'react';
import '../components/LoadingSpinner.css'; // Import the CSS file

const LoadingSpinner = () => (
  <div className="spinner">
    <div className="double-bounce1"></div>
    <div className="double-bounce2"></div>
  </div>
);

export default LoadingSpinner;
