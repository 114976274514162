// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* src/components/TestCasesContainer.css */

.testcases-container {
  padding: 40px 20px;
  animation: fadeIn 0.6s ease-out;
}

.testcases-container h2 {
  text-align: center;
  margin-bottom: 30px;
  color: var(--accent-color);
}

.cards {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
  animation: slideIn 0.6s ease-out;
}

.api-input {
  margin: 30px auto;
  max-width: 500px;
  text-align: center;
}

.api-input input {
  padding: 10px;
  width: 80%;
  border: 1px solid #ccc;
  border-radius: 4px;
  transition: border-color var(--transition-speed);
}

.api-input input:focus {
  border-color: var(--accent-color);
  outline: none;
}

.response {
  max-width: 800px;
  margin: 20px auto;
  padding: 20px;
  background-color: #f1f1f1;
  border-left: 4px solid var(--primary-color);
  border-radius: 4px;
  overflow-x: auto;
  animation: fadeIn 0.6s ease-out;
}

.response h3 {
  margin-bottom: 10px;
  color: var(--accent-color);
}

.response pre {
  white-space: pre-wrap;
  word-wrap: break-word;
  font-family: 'Courier New', Courier, monospace;
  color: #333;
}

.error {
  color: var(--danger-color);
  text-align: center;
  margin-top: 10px;
}
`, "",{"version":3,"sources":["webpack://./src/components/TestCasesContainer.css"],"names":[],"mappings":"AAAA,0CAA0C;;AAE1C;EACE,kBAAkB;EAClB,+BAA+B;AACjC;;AAEA;EACE,kBAAkB;EAClB,mBAAmB;EACnB,0BAA0B;AAC5B;;AAEA;EACE,aAAa;EACb,eAAe;EACf,SAAS;EACT,uBAAuB;EACvB,gCAAgC;AAClC;;AAEA;EACE,iBAAiB;EACjB,gBAAgB;EAChB,kBAAkB;AACpB;;AAEA;EACE,aAAa;EACb,UAAU;EACV,sBAAsB;EACtB,kBAAkB;EAClB,gDAAgD;AAClD;;AAEA;EACE,iCAAiC;EACjC,aAAa;AACf;;AAEA;EACE,gBAAgB;EAChB,iBAAiB;EACjB,aAAa;EACb,yBAAyB;EACzB,2CAA2C;EAC3C,kBAAkB;EAClB,gBAAgB;EAChB,+BAA+B;AACjC;;AAEA;EACE,mBAAmB;EACnB,0BAA0B;AAC5B;;AAEA;EACE,qBAAqB;EACrB,qBAAqB;EACrB,8CAA8C;EAC9C,WAAW;AACb;;AAEA;EACE,0BAA0B;EAC1B,kBAAkB;EAClB,gBAAgB;AAClB","sourcesContent":["/* src/components/TestCasesContainer.css */\n\n.testcases-container {\n  padding: 40px 20px;\n  animation: fadeIn 0.6s ease-out;\n}\n\n.testcases-container h2 {\n  text-align: center;\n  margin-bottom: 30px;\n  color: var(--accent-color);\n}\n\n.cards {\n  display: flex;\n  flex-wrap: wrap;\n  gap: 20px;\n  justify-content: center;\n  animation: slideIn 0.6s ease-out;\n}\n\n.api-input {\n  margin: 30px auto;\n  max-width: 500px;\n  text-align: center;\n}\n\n.api-input input {\n  padding: 10px;\n  width: 80%;\n  border: 1px solid #ccc;\n  border-radius: 4px;\n  transition: border-color var(--transition-speed);\n}\n\n.api-input input:focus {\n  border-color: var(--accent-color);\n  outline: none;\n}\n\n.response {\n  max-width: 800px;\n  margin: 20px auto;\n  padding: 20px;\n  background-color: #f1f1f1;\n  border-left: 4px solid var(--primary-color);\n  border-radius: 4px;\n  overflow-x: auto;\n  animation: fadeIn 0.6s ease-out;\n}\n\n.response h3 {\n  margin-bottom: 10px;\n  color: var(--accent-color);\n}\n\n.response pre {\n  white-space: pre-wrap;\n  word-wrap: break-word;\n  font-family: 'Courier New', Courier, monospace;\n  color: #333;\n}\n\n.error {\n  color: var(--danger-color);\n  text-align: center;\n  margin-top: 10px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
