// src/components/SignIn.js

import React, { useRef, useState } from 'react';
import { auth, googleProvider } from '../firebase';
import { signInWithEmailAndPassword, signInWithPopup } from 'firebase/auth';
import googleImg from '../assets/google.png'; // Import the Google logo
import './SignIn.css'; // Import the CSS file
import { useNavigate } from 'react-router-dom';

function SignIn() {
  const emailRef = useRef();
  const passwordRef = useRef();
  const [error, setError] = useState('');
  const navigate = useNavigate();

  const handleEmailSignIn = async (e) => {
    e.preventDefault();
    try {
      setError('');
      await signInWithEmailAndPassword(
        auth,
        emailRef.current.value,
        passwordRef.current.value
      );
      const response = await fetch('https://twingen-api-bdgmg2csc7ceb5fv.centralindia-01.azurewebsites.net/api/addUser', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({user_id: auth.currentUser.uid, email: auth.currentUser.email, username: auth.currentUser.displayName}),
      });

      if (!response.ok) {
        throw new Error('Failed to add user to database.');
      }
      else {
        console.log('User added to database.');
      }
    } catch (err) {
      setError(err.message);
    }
  };

  const handleGoogleSignIn = async () => {
    try {
      setError('');
      await signInWithPopup(auth, googleProvider);

      const response = await fetch('https://twingen-api-bdgmg2csc7ceb5fv.centralindia-01.azurewebsites.net/api/addUser', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({user_id: auth.currentUser.uid, email: auth.currentUser.email, username: auth.currentUser.displayName}),
      });

      if (!response.ok) {
        navigate('/');
      }
      else {
        console.log('User added to database.');
        navigate('/');
      }

    } catch (err) {
      setError(err.message);
    }
  };

  return (
    <div className="signin-container fade-in">
      <h2>Sign In</h2>
      {error && <p className="error">{error}</p>}
      <form onSubmit={handleEmailSignIn}>
        <input type="email" ref={emailRef} className="input-field" placeholder="Email" required />
        <input type="password" ref={passwordRef} className="input-field" placeholder="Password" required />
        <button type="submit" className="btn">Sign In</button>
      </form>
      <p style={{ display: 'flex', justifyContent: 'center' }}>OR</p>
      <button onClick={handleGoogleSignIn} className="google-button"><img src={googleImg} style={{ height: '20px', marginRight: '20px' }}/>Continue with Google</button>
    </div>
  );
}

export default SignIn;
