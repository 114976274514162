// src/components/TestCaseCard.js

import React from 'react';
import './TestCaseCard.css'; // Import the CSS file

function TestCaseCard({ testCase, onSelect }) {
  return (
    <div className="testcase-card" onClick={() => onSelect(testCase.chatHistory)}>
      <h3>{testCase.title}</h3>
      <p>{testCase.chatHistory[0].text}...</p>
    </div>
  );
}

export default TestCaseCard;
