// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* src/components/TestCaseCard.css */

.testcase-card {
    background-color: var(--light-color);
    border: 1px solid #eaeaea;
    border-radius: 8px;
    padding: 20px;
    width: 300px;
    cursor: pointer;
    transition: transform var(--transition-speed), box-shadow var(--transition-speed);
    position: relative;
    overflow: hidden;
  }
  
  .testcase-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  }
  
  .testcase-card h3 {
    margin-bottom: 10px;
    color: var(--accent-color);
  }
  
  .testcase-card p {
    color: var(--secondary-color);
  }
  
  .testcase-card::after {
    content: '';
    position: absolute;
    top: 0;
    left: -100%;
    width: 100%;
    height: 100%;
    background: linear-gradient(120deg, var(--accent-color), transparent);
    transition: all 0.5s;
  }
  
  .testcase-card:hover::after {
    left: 100%;
  }
  `, "",{"version":3,"sources":["webpack://./src/components/TestCaseCard.css"],"names":[],"mappings":"AAAA,oCAAoC;;AAEpC;IACI,oCAAoC;IACpC,yBAAyB;IACzB,kBAAkB;IAClB,aAAa;IACb,YAAY;IACZ,eAAe;IACf,iFAAiF;IACjF,kBAAkB;IAClB,gBAAgB;EAClB;;EAEA;IACE,2BAA2B;IAC3B,yCAAyC;EAC3C;;EAEA;IACE,mBAAmB;IACnB,0BAA0B;EAC5B;;EAEA;IACE,6BAA6B;EAC/B;;EAEA;IACE,WAAW;IACX,kBAAkB;IAClB,MAAM;IACN,WAAW;IACX,WAAW;IACX,YAAY;IACZ,qEAAqE;IACrE,oBAAoB;EACtB;;EAEA;IACE,UAAU;EACZ","sourcesContent":["/* src/components/TestCaseCard.css */\n\n.testcase-card {\n    background-color: var(--light-color);\n    border: 1px solid #eaeaea;\n    border-radius: 8px;\n    padding: 20px;\n    width: 300px;\n    cursor: pointer;\n    transition: transform var(--transition-speed), box-shadow var(--transition-speed);\n    position: relative;\n    overflow: hidden;\n  }\n  \n  .testcase-card:hover {\n    transform: translateY(-5px);\n    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);\n  }\n  \n  .testcase-card h3 {\n    margin-bottom: 10px;\n    color: var(--accent-color);\n  }\n  \n  .testcase-card p {\n    color: var(--secondary-color);\n  }\n  \n  .testcase-card::after {\n    content: '';\n    position: absolute;\n    top: 0;\n    left: -100%;\n    width: 100%;\n    height: 100%;\n    background: linear-gradient(120deg, var(--accent-color), transparent);\n    transition: all 0.5s;\n  }\n  \n  .testcase-card:hover::after {\n    left: 100%;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
